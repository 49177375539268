import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import whitlistData from './whitelist.json';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  export const StyledButton = styled.button`
  // border-radius: 5px;
  // font-family: 'Boogaloo', cursive;
  // border: none;
  // background-color: var(--secondary);
  // font-size: 30px;
  // padding: 19px;
  // font-weight: bold;
  // color: var(--accent);
  // width: 100%;
  // cursor: pointer;
  // :active {
  //   box-shadow: none;
  //   -webkit-box-shadow: none;
  //   -moz-box-shadow: none;
  // }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  font-family: 'Poppins', sans-serif;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(251,150,71);
  background: linear-gradient(145deg, rgba(251,150,71,1) 0%, rgba(255,86,95,1) 100%);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  // width: 100%;
  // max-width: 500px;
  // transition: width 0.5s;
`;

export const StyledLink = styled.a`
  // color: var(--secondary);
  // text-decoration: none;
`;

export const Ulist = styled.ul`
`;

export const List = styled.li`
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    WHITELIST: false,
  });

  const isWhitList = () => {
    let $isWhite = false
    whitlistData.map((item) => {
      if (blockchain.account.toLowerCase() == item.wallet.toLowerCase()){
        $isWhite = true
      }
    });
  
    return $isWhite;
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit + 5000 * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount, "achraf123")
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("./config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen id="hero" className="text-center">
      
      <s.Container className="logo" >
        <StyledLink href={"#"}>
            <StyledImg alt={"example"} src={"./config/img/logo.png"} />
        </StyledLink>
      </s.Container>

      <s.Container className="girl" >
        <StyledImg alt={"example"} src={"./config/img/girl.png"} />
      </s.Container>
      
          <s.Container className="text">
            {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                  <s.TextTitle
                  >
                    {/* Mint your {CONFIG.NFT_NAME} */}
                  </s.TextTitle>
                  </>
                ) : (
                  <>
                  <s.TextTitle
                  >
                    {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                  </s.TextTitle>
                  </>
                )}
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle
                >
                  Pre-Private Sale Price {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>

                
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                    <s.TextDescription>
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerMedium />
                  <s.Container className="textbutton">
                    <img src="config/img/button.png" alt="images not found"></img>
                    <StyledButton className="main-button"
                      onClick={(e) => {
                        if(minting == true){
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }
                      }}
                    >
                      <img src="config/img/icon.png" alt="images not found"></img>
                      Connect Wallet
                    </StyledButton>
                  </s.Container>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                  </>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container className="counter">
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <StyledButton className="qty"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          setMintAmount(3);
                        }}
                      >
                        3
                      </StyledButton>
                      <StyledButton className="qty"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          setMintAmount(5);
                        }}
                      >
                        5
                      </StyledButton>
                      <StyledButton className="qty"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          setMintAmount(10);
                        }}
                      >
                        10
                      </StyledButton>
                    <s.SpacerSmall />
                    <s.Container className="textbutton">
                    <img src="config/img/button.png" alt="images not found"></img>
                    {(isWhitList() || CONFIG.WHITELIST == false ? (
                      <StyledButton className="main-button"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                      ) : (
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontSize: "25px",
                          }}
                        >
                          You are not whitelisted
                        </s.TextDescription>)
                      )}
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
    </s.Screen>
  );
}

export default App;
